import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {Box, Typography} from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.primary.contrastText,
    color: theme.palette.secondary.main,
    textAlign: 'center',
    padding: theme.spacing(4, 2),
  },
  text: {
    fontSize: '1rem',
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.35rem',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '1.7rem',
    },
    fontWeight: '600',
  }
}))

function KilometerStand() {
  const classes = useStyles()

  return (
    <Box className={classes.root} >
      <Typography
        className={classes.text}
        component="div"
        variant="h5"
      >
        Aktuell xxx von 3200 km
      </Typography>
    </Box>
  )
}
export default KilometerStand