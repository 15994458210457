import React from 'react'
import { Link } from 'react-router-dom'
import { Box, ButtonGroup, ButtonBase } from '@material-ui/core'
import Icon from '../img/Icon01.png'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.primary.contrastText,
    color: theme.palette.primary.main,
    padding: theme.spacing(4, 2),
  },
  buttonGroup: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    [theme.breakpoints.up('md')]: {
      flexWrap: 'nowrap',
    },
  },
  menuButton: {
    display: 'flex',
    flexDirection: 'column',
    width: '30%',
    marginBottom: theme.spacing(4),
    [theme.breakpoints.up('md')]: {
      width: '10%',
      marginBottom: 0,
    },
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.secondary.main,
    fontWeight: 600,
    '&:hover': {
      opacity: 0.7,
    }
  },
  iconWrap: {
    backgroundColor: theme.palette.primary.main,
    backgroundSize: 'cover',
    padding: theme.spacing(1),
    width: '3rem',
    height: '3rem',
    marginBottom: theme.spacing(1),
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.up('sm')]: {
      width: '4rem',
      height: '4rem',  
    }
  },
  icon: {
    
  },
  buttonText: {
    fontSize: '0.8rem',
    [theme.breakpoints.up('sm')]: {
      fontSize: '1rem',
    }
  },
}))

const menuItems = [
  { title: 'Der Weg', slug: '/der-weg' },
  { title: 'Etappen', slug: '/etappen' },
  { title: 'Orte & Geschichten', slug: '/stories' },
  { title: 'Kulinarisches', slug: '/rezepte' },
  { title: 'Musikalisches', slug: '/musik' },
  { title: 'About us', slug: '/about-us' }
]

const Menu = () => {
  const classes = useStyles()

  return (
    <Box
      className={ classes.root }
      borderBottom={3}
      borderColor="primary.main"
    >
      <ButtonGroup
        size="large"
        color="secondary"
        aria-label="outlined secondary button group"
        className={classes.buttonGroup}
      >
        {menuItems.map(( item, i ) =>
          <ButtonBase
            key={i}
            className={ classes.menuButton }
            flexShrink={1}
          >
            <Link to={item.slug} key={i} className={classes.link}>
              <Box
                className={ classes.iconWrap }
                style={{
                  backgroundImage: `url(${Icon})`,
                }}
              >
              </Box>
              <Box
                component="span"
                className={classes.buttonText}
              >
                { item.title }
              </Box>
            </Link>
          </ButtonBase>
        )}
      </ButtonGroup>
    </Box>
  )
}

export default Menu