import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { withStyles } from '@material-ui/core/styles'
import MuiAccordion from '@material-ui/core/Accordion'
import MuiAccordionSummary from '@material-ui/core/AccordionSummary'
import MuiAccordionDetails from '@material-ui/core/AccordionDetails'
import Card from '@material-ui/core/Card'
import CardMedia from '@material-ui/core/CardMedia'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import SectionHeader from './SectionHeader'
import Etappen from './Etappen'

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  bgGreen: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText
  },
  bgReverse: {
    backgroundColor: theme.palette.secondary.contrastText,
    color: theme.palette.secondary.main
  },
}))

const AbschnittCard = withStyles({
  root: {
    backgroundColor: 'transparent',
    color: 'inherit',
    boxShadow: 'none',
    display: 'flex'
  }
})(Card)

const AbschnittMedia = withStyles({
  root: {
    width: 80,
    borderRadius: 18,
  },
  media: {
    
  }
})(CardMedia)

const AbschnittTitel = withStyles({
  root: {
    textTransform: 'uppercase'
  }
})(CardContent)


const Abschnitt = (props) => {
  const abschnittsbild = props.data.acf.abschnittsbild

  return (
    <AbschnittCard
      square
    >
      {abschnittsbild &&
      <AbschnittMedia
        image={abschnittsbild.sizes['post-thumbnail']}
      />
      }
      <AbschnittTitel>
        <Typography
          component="h2"
          variant="h5"
          // className={classes.heading}
        >
          {props.data.name}
        </Typography>
      </AbschnittTitel>
    </AbschnittCard>
  )
}

const Accordion = withStyles({
  root: {
    textAlign: 'left',
    boxShadow: 'none',
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      marginTop: '0',
    },
  },
  expanded: {},
})(MuiAccordion)

const AccordionSummary = withStyles({
  root: {
    minHeight: 90,
    margin: 0,
    '&$expanded': {
      // margin: '18px 0',
      minHeight: 90,
    },
  },
  content: {
    margin: '20px 0',
    '&$expanded': {
      margin: '20px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary)

const AccordionDetails = withStyles({
  root: {
    display: 'block',
  }
})(MuiAccordionDetails)

const Abschnitte = (props) => {
  const { etappen, abschnitte, rezepte } = props
  const [expanded, setExpanded] = useState(false)
  const classes = useStyles()

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  }

  return (
    <div className="">
      <SectionHeader title='Etappen' />
      {abschnitte.map((abschnitt, i) => (
        <Accordion
          key={i}
          square
          expanded={expanded === ('panel' + i)}
          onChange={handleChange('panel' + i)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={'panel' + i + 'bh-content'}
            id={'panel' + 1 + 'bh-header'}
            className={i % 2 === 0 ? classes.bgGreen : classes.bgReverse}
          >
            <Abschnitt data={abschnitt} />
          </AccordionSummary>
          <AccordionDetails
            className={i % 2 === 0 ? classes.bgGreen : classes.bgReverse}
          >
            <Etappen abschnitt={abschnitt.id} etappenAll={etappen} rezepte={rezepte} />
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  )
}

export default Abschnitte