import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'
import Axios from 'axios'
import '@fontsource/roboto'
import { Container } from '@material-ui/core'
import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/styles'
import Header from './components/Header'
import Menu from './components/Menu'
import KilometerStand from './components/KilometerStand'
import Abschnitte from './components/Abschnitte'
import Rezepte from './components/Rezepte'
import AboutUs from './components/AboutUs'
import EtappenNav from './components/EtappenNav'
import './App.css'

let theme = createMuiTheme({
  breakpoints: {
    values: {
    xs: 0,
    sm: 400,
    md: 680,
    lg: 1024,
    xl: 1200,
    }
  }
})

theme = responsiveFontSizes(theme)

theme.palette.primary = {
  light: '#80443b',
  main: '#501B15',
  dark: '#2c0000',
  contrastText: '#FCF8EA',
}
theme.palette.secondary = {
  light: '#7e854e',
  main: '#515824',
  dark: '#282f00',
  contrastText: '#fff',
}
theme.typography.h1 = {

}

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      siteInfo: {},
      siteimage: [],
      etappen: [],
      abschnitte: [],
      rezepte: [],
      loading: false,
      error: null
    }

    this.getWpData = this.getWpData.bind(this)
  }

  componentDidMount() {
    this.getWpData()
  }

  getWpData = () => {
    const token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczpcL1wvZ2Fvdy5iZXJsaW4tYmFzZW1lbnQuZGUiLCJpYXQiOjE2MjI0MTg2NzEsIm5iZiI6MTYyMjQxODY3MSwiZXhwIjoxNjIzMDIzNDcxLCJkYXRhIjp7InVzZXIiOnsiaWQiOiIxIn19fQ.ha_h8qX_lYym5KkCDg3FlizMVoFWWlpFkDX1p0_xOrk'
    const baseURL = 'https://gaow.berlin-basement.de/wp-json'
    this.setState({
      loading: true
    })

    Axios
      .get( baseURL + '/wp/v2/pages?slug=startseite' )
      .then( res => {
        if (res.data[0].featured_media) {
          return Axios.get( baseURL + '/wp/v2/media/' + res.data[0].featured_media )
        }
      })
      .then( res => {
        if (res) {
          this.setState({ siteimage: res.data.media_details.sizes.large })
        }
        return Axios.get( baseURL + '/wp/v2/etappen?order=asc&per_page=50' )
      })
      .then( res => {
        this.setState({ etappen: res.data })
        return Axios.get( baseURL + '/wp/v2/abschnitte?order=asc&per_page=20' )
      })
      .then( res => {
        this.setState({ abschnitte: res.data })
        return Axios.get( baseURL + '/wp/v2/rezepte?order=asc&per_page=20' )
      })
      .then( res => {
        this.setState({
          rezepte: res.data,
          loading: false
        })
      })
      .catch( err => {
        this.setState({ error: true })
        console.log(err.res)
      })
  }

  render() {
    const { siteInfo, siteimage, etappen, abschnitte, rezepte } = this.state
    return (
      <ThemeProvider theme={theme}>
        <div className="App">
          <Container maxWidth='lg' disableGutters>
            <Switch>
              <Route exact path="/">
                <Header
                  // siteInfo={siteInfo}
                  siteImage={siteimage}
                />
              </Route>
              <Route path="/">
                <Header
                  // siteInfo={siteInfo}
                  siteImage={null}
                />
              </Route>
            </Switch>
          </Container>
          <Container maxWidth='lg' disableGutters>
            <Menu
              // siteInfo={siteInfo}
            />
            <Route exact path="/">
              <EtappenNav />
              <KilometerStand />
            </Route>
          </Container>
          <Switch>
            <Route exact path="/">
            </Route>
            <Route path="/etappen">
              {abschnitte && etappen &&
                <Container maxWidth='lg' disableGutters>
                  <Abschnitte etappen={etappen} abschnitte={abschnitte} rezepte={rezepte} />
                </Container>
              }
            </Route>
            <Route path="/rezepte">
              {rezepte && etappen &&
                <Container maxWidth='lg' disableGutters>
                  <Rezepte rezepte={rezepte} etappen={etappen} />
                </Container>
              }
            </Route>
            <Route path="/about-us">
              {rezepte && etappen &&
                <Container maxWidth='lg' disableGutters>
                  <AboutUs rezepte={rezepte} etappen={etappen} />
                </Container>
              }
            </Route>
          </Switch>
        </div>
      </ThemeProvider>
    )
  }
}

export default App
