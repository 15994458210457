import React from 'react'
import { Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Icon from '../img/Icon01.png'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    padding: '1rem',
  },
  title: {
    textTransform: 'uppercase'
  },
  icon: {
    height: 'auto',
    width: '65px'
  },
  headertext: {

  }
}))

const SectionHeader = (props) => {
  const classes = useStyles()

  return (
    <Box
      className={classes.root}
      display="flex"
      flexDirection="row"
    >
      <Box
        pr={3}
      >
        <img src={Icon} className={classes.icon} alt="Wander-Icon" />
      </Box>
      <Box
        className={classes.headertext}
      >
        <Typography
          component="h3"
          variant="h2"
          className={classes.title}
        >
          {props.title}
        </Typography>
      </Box>
    </Box>
  )
}

export default SectionHeader