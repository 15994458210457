import React from 'react'
import { Link } from 'react-router-dom'
import { Box, Card, CardMedia, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import {ReactComponent as GentleLogo} from './../img/gentle-logo.svg'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    textAlign: 'center',
  },
  logo: {
    maxWidth: '100%',
    maxHeight: '150px',
    height: 'auto',
  },
  homelink: {
    color: theme.palette.primary.contrastText,
    textDecoration: 'none'
  },
  siteName: {
    fontSize: '2rem',
    [theme.breakpoints.up('sm')]: {
      fontSize: '2.5rem',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '3rem',
    },
    textTransform: 'uppercase'
  },
  subtitle: {
    fontSize: '1rem',
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.2rem',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '1.4rem',
    }
  },
  image: {
    height: '50vw',
  }
}))

const Header = (props) => {
  const title = 'The Gentle Art of Walking'
  const description = 'Zu Fuß von Berlin an den östlichsten Punkt auf dem europäischen Festland'
  const siteImage = props.siteImage
  const classes = useStyles()

  return (
    <Card
      className={classes.root}
      square
    >
      <Link to="/" className={classes.homelink}>
        <Box
          display='flex'
        >
          <Box
            flexShrink={1}
            display='flex'
            flexDirection='column'
            justifyContent='center'
            px={2}
            py={3}
          >
            <GentleLogo className={classes.logo} />
          </Box>
          <Box
            flexGrow={1}
            flexShrink={1}
            px={2}
            py={3}
          >
            <Typography
              variant="h1"
              className={classes.siteName}
              align="left"
            >
              {title}
            </Typography>
            <Typography
              variant="subtitle1"
              className={classes.subtitle}
              align="left"
            >
              {description}
            </Typography>
          </Box>
        </Box>
        {siteImage && siteImage.source_url &&
          <CardMedia
            image={siteImage.source_url}
            className={classes.image}
          />
        }
      </Link>
    </Card>
  )
}

export default Header