import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { withStyles } from '@material-ui/core/styles'
import MuiAccordion from '@material-ui/core/Accordion'
import MuiAccordionSummary from '@material-ui/core/AccordionSummary'
import MuiAccordionDetails from '@material-ui/core/AccordionDetails'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import MuiBox from '@material-ui/core/Box'
import MuiGridList from '@material-ui/core/GridList'
import MuiGridListTile from '@material-ui/core/GridListTile'
import MuiButton from '@material-ui/core/Button'
import MuiList from '@material-ui/core/List'
import MuiListItem from '@material-ui/core/ListItem'
import MuiListItemText from '@material-ui/core/ListItemText'

const useStyles = makeStyles((theme) => ({
  bgGreen: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText
  },
  bgReverse: {
    backgroundColor: theme.palette.secondary.contrastText,
    color: theme.palette.secondary.main
  },
  etappenSubtitle: {
    paddingLeft: '1.5rem'
  },
  accordionDetails: {
    // paddingRight: 0,
  },
  galerieWrap: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    maxWidth: '550px',
    padding: theme.spacing(5),
    margin: '0 auto',
    overflow: 'hidden',

  },
  galerie: {

  },
  foto: {

  }
}))

const Accordion = withStyles({
  root: {
    boxShadow: 'none',
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      marginTop: '0',
    },
    // paddingRight: '0',
  },
  expanded: {},
})(MuiAccordion)

const AccordionSummary = withStyles({
  root: {
    margin: 0,
    '&$expanded': {
      // margin: '18px 0',
    },
  },
  content: {
    display: 'block',
    // paddingRight: '0',
    margin: '20px 0',
    '&$expanded': {
      margin: '20px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary)


const EtappenBox = withStyles({
  root: {
  }
})(MuiBox)


const Fotogalerie = (props) => {
  const classes = useStyles()
  return (
    <div className={classes.galerieWrap}>
      <MuiGridList
        className={classes.galerie}
        cellHeight={160}
        cols={3}
      >
        {props.fotos.map((foto) => (
          <MuiGridListTile
            className={classes.foto}
            key={foto.filename}
            cols={foto.cols || 1}
          >
            <img src={foto.sizes.medium} alt={foto.title} />
          </MuiGridListTile>
        ))}
      </MuiGridList>
    </div>
  )
}

const RezeptButton = withStyles({
  root: {
  },
  text: {
    color: 'inherit'
  }
})(MuiButton)

const RezeptLinks = (props) => {
  const rezepte = props.rezepte
  const classes = useStyles()
  return (
    <MuiBox>
      <Typography
        component="h3"
        variant="h6"
      >
        {'Rezepte:'}
      </Typography>
      <MuiList>
        {props.rezeptIds.map((id) => {
          const node = rezepte.filter((r) => {
            return r.id === id
          })
          if (node.length > 0) {
            return (
              <MuiListItem
                button
                key={id}
              >
                <Link to={'/rezepte#rezept-' + id} className={classes.link}>
                  <MuiListItemText primary={node[0].title.rendered} />
                </Link>
              </MuiListItem>
            )
          } else {
            return null
          }
        })}
      </MuiList>
    </MuiBox>
  )
}


const EtappenDetails = (props) => {
  const { entfernung, hoehe } = props.etappe
  const content = props.etappe.content.rendered
  const fotos = props.etappe.acf.fotos
  const rezeptIds = props.etappe.acf.rezept_referenz
  const rezepte = props.rezepte

  return (
    <EtappenBox>
      {entfernung && 
        <span>{entfernung} km</span>
      }
      {entfernung && hoehe && <span> - </span>}
      {hoehe && 
        <span>{hoehe} Höhenmeter</span>
      }
      {content &&
        <Typography
          dangerouslySetInnerHTML={{__html: content}}
        />
      }
      {fotos && 
        <Fotogalerie fotos={fotos} />
      }
      {rezeptIds &&
        <RezeptLinks rezeptIds={rezeptIds} rezepte={rezepte} />
      }
    </EtappenBox>
  )
}

const EtappenDatum = (props) => {
  const classes = useStyles()
  let newDate = null
  const date = props.date
  if (date !== '') {
    var dateSplitPattern = /(\d{4})(\d{2})(\d{2})/
    newDate = new Date(date.replace(dateSplitPattern,'$1-$2-$3'))
  }
  if (!newDate) {
    return null
  }
  return (
    <Typography
      component="span"
      variant="subtitle1"
      className={classes.etappenSubtitle}
    >
      am<> </>
      {new Intl.DateTimeFormat("de-DE", {
        year: "numeric",
        month: "numeric",
        day:"numeric"
      }).format(newDate)}
    </Typography>
  )
}

const EtappenTitel = (props) => {
  const date = props.datum
  const title = props.titel
  return (
    <>
      <Typography
        component="h3"
        variant="h6"
        dangerouslySetInnerHTML={{__html: title}}
      />
      {date && <EtappenDatum date={date} />}
    </>
  )
}

const Etappen = (props) => {
  const etappen = props.etappenAll.filter(a => {
    if (a.abschnitte) {
      let abschnitt = a.abschnitte[0]
      return abschnitt === props.abschnitt
    } else {
      return null
    }
  })
  const rezepte = props.rezepte

  const [expanded, setExpanded] = useState()
  const classes = useStyles()

  const handleChange = (subpanel) => (event, isExpanded) => {
    setExpanded(isExpanded ? subpanel : false);
  }

  return (
    <>
      {etappen.map((etappe, i) => (
        <Accordion
          key={i}
          square
          expanded={expanded === ('subpanel' + i)}
          onChange={handleChange('subpanel' + i)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={'panel' + i + 'bh-content'}
            id={'panel' + 1 + 'bh-header'}
            className={`${i % 2 === 0 ? classes.bgGreen : classes.bgReverse} ${classes.accordionSummary}`}
          >
            <EtappenTitel
              titel={etappe.title.rendered}
              datum={etappe.etappendatum}
            />
          </AccordionSummary>
          <MuiAccordionDetails
            className={`${i % 2 === 0 ? classes.bgGreen : classes.bgReverse} ${classes.accordionDetails}`}
          >
            <EtappenDetails etappe={etappe} rezepte={rezepte} />
          </MuiAccordionDetails>
        </Accordion>
      ))}
    </>
  )
}

export default Etappen