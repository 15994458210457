import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {Box, ButtonBase, ButtonGroup, Typography} from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.primary.contrastText,
    color: theme.palette.secondary.main,
    padding: theme.spacing(4, 2),
  },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  arrowLeft: {
    width: 0,
    height: 0,
    borderStyle: 'solid',
    borderWidth: '18px 65px 18px 0',
    [theme.breakpoints.up('sm')]: {
      borderWidth: '30px 105px 30px 0',
    },
    [theme.breakpoints.up('md')]: {
      borderWidth: '45px 160px 45px 0',
    },
    borderColor: 'transparent ' + theme.palette.primary.main + ' transparent transparent',
    marginRight: theme.spacing(2),
  },
  arrowRight: {
    width: 0,
    height: 0,
    borderStyle: 'solid',
    borderWidth: '18px 0 18px 65px',
    [theme.breakpoints.up('sm')]: {
      borderWidth: '30px 0 30px 105px',
    },
    [theme.breakpoints.up('md')]: {
      borderWidth: '45px 0 45px 160px',
    },
    borderColor: 'transparent transparent transparent' + theme.palette.primary.main,
    marginLeft: theme.spacing(2),
  },
  text: {
    fontSize: '1rem',
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.35rem',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '1.7rem',
    },
    fontWeight: '600',
  },
  textLeft: {
    paddingRight: theme.spacing(2),
  },
  textRight: {
    paddingLeft: theme.spacing(2),
  }
}))

function EtappenNav() {
  const classes = useStyles()

  return (
    <Box
      className={classes.root}
      borderBottom={3}
      borderColor="primary.main"
    >
      <ButtonGroup
        className={classes.buttonGroup}
      >
        <ButtonBase>
          <span className={classes.arrowLeft}></span>
          <Typography
            component="div"
            variant="h5"
            align="left"
            className={[classes.text, classes.textLeft]}
          >
          Jüngste Etappe
          </Typography>
        </ButtonBase>
        <ButtonBase>
          <Typography
            component="div"
            variant="h5"
            align="right"
            className={[classes.text, classes.textRight]}
          >
          Nächste Etappe
          </Typography>
          <span className={classes.arrowRight}></span>
        </ButtonBase>
      </ButtonGroup>
    </Box>
  )
}
export default EtappenNav