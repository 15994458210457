import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {Box, Typography} from '@material-ui/core'

import SectionHeader from './SectionHeader'

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  bgGreen: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText
  },
  bgReverse: {
    backgroundColor: theme.palette.secondary.contrastText,
    color: theme.palette.secondary.main
  },
}))

function AboutUs(props) {
  const classes = useStyles

  return (
    <Box className={classes.root} >
      <SectionHeader title='About us' />
      {props.rezepte.map((rezept, i) => {
        return (
          <Box key={i}>
            <Typography
              component="h2"
              variant="h5"
              dangerouslySetInnerHTML={{__html: rezept.title.rendered}}
            />
            <Typography
              component="div"
              // variant="h5"
              dangerouslySetInnerHTML={{__html: rezept.content.rendered}}
            />
          </Box>
        )
      })}
    </Box>
  )
}
export default AboutUs